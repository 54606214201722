import {Route, Switch, useRouteMatch} from "react-router-dom";


import Dashboard from "./dashboard";

import SaveHazards from "./saveHazards/container"
import LongitudinalHazard from "./saveHazards/longitudinalHazards";

const  Administration = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();

  return (
  <div>
    <Switch>
      <Route exact path={path}>
        <Dashboard/>
      </Route>
      <Route path={`${path}/saveHazards`}>
        <SaveHazards />
      </Route>
      <Route path={`${path}/saveLongitudinalHazards`}>
        <LongitudinalHazard />
      </Route>
      
    </Switch>
  </div>
  );
}

export default Administration;