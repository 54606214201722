
import { Fragment } from "react";
import RiskOutputPanel from "./riskOutputPanel";

const TopTenRisks = (props) => {
  
  const {
    topTenRisks,
    age,
    gender,
    correlation,
  } = props;

  let risksWithHazards = [];
  if(topTenRisks && topTenRisks.length > 0) {
    risksWithHazards = topTenRisks.filter(risk => {
      return risk.hazardRate >0
    })
  }

  
  // const tableCellClassName = `table-cell px-2 border-b-2 border-dotted align-middle`;
  const tableCellClassName =` table-cell px-2 align-middle`
  return (
    <div>
      <div className={`font-bold text-gray-800 ${tableCellClassName} border-0`}> 
        Worst 10 hazards &nbsp;
        {
          props.selectedMethod === 3 && props.calculatedRatio !== '' &&
          <Fragment>[Ratio - {props.calculatedRatio.toPrecision(3)}]</Fragment>
        }
      </div>
      <div className={`table w-full`}>
        {

          risksWithHazards.map((risk,idx)=>{
            return (
              <RiskOutputPanel
                key={`OutputRow_${risk.biomarkerId}`}
                topTenRisks={topTenRisks}
                biomarker={risk.biomarker}
                shortName={risk.shortName}
                biomarkerId={risk.biomarkerId}
                unit={risk.unit}
                unitId={risk.unitId}
                value={risk.value}
                hazardRate={risk.hazardRate}
                age={age}
                gender={gender}
                correlation={correlation}
                longit={risk.longit}
              />

            )
          })
        }
      </div>
    </div>
  )
}

export default TopTenRisks;