
import httpClient from "../../httpClient";
import {useCallback, useEffect, useState} from "react";
import _ from 'lodash';

const ProjectedAgeRow = (props) => {
  let {title, hazardRate, className} = props;
  let displayHazardRate;
  if(title === "Actuarial") {
    displayHazardRate=''
  } else {
    displayHazardRate = hazardRate;
  }
  // const [fivePercentileAge, setFivePercentileAge] = useState(null);
  // const [fiftyPercentileAge, setFiftyPercentileAge] = useState(null);
  // const [hr, setHr] = useState(props.hazardRate);
  const [uHEstimatedAge, setUHEstimatedAge] = useState(null);
  const getAges = useCallback(async (hazardRate, age, gender, stochasticOption) =>  {
    return await httpClient.post('/risk/v1/getProjectedAge', {
      hazardRate: hazardRate,
      age:age,
      gender:gender,
      stochasticOption:stochasticOption,
    });
  },[]);
  const getProjectedAge = useCallback(async (hazardRate, age, gender, stochasticOption) => {
    if(hazardRate<1) {
      return;
    }
    const ages = await getAges(hazardRate, age, gender, stochasticOption);
    const actuarialAges = await getAges(stochasticOption, age, gender, stochasticOption);
    // setFivePercentileAge(ages.fivePercentileAge)
    setUHEstimatedAge(_.round(age-ages.fiftyPercentileAge+actuarialAges.fiftyPercentileAge,2));
  },[getAges]);

  

  useEffect(() => {
    getProjectedAge(props.hazardRate, props.age, props.gender, props.stochasticOption)
  }, [props.hazardRate, props.age, props.gender, props.stochasticOption, getProjectedAge]);
  
  

  return (
    <div className={`table-row`}>
      <div className={`${className} w-1/2`}>
        {title}
      </div>
      <div className={`${className} text-right w-1/3`}>
        {displayHazardRate}
      </div>
      <div className={`${className} text-right w-1/6`}>
        {uHEstimatedAge}
      </div>

    </div>
  )
}

// Button.propTypes = {
//   title: PropTypes.string.isRequired,
//   hazardRate:PropTypes.number.isRequired,
// }

export default ProjectedAgeRow;