import {Link} from  "react-router-dom";

const RouteLink = (props) => {
    const content = props.children ? props.children : props.text;
    return (

        <Link 
            to={props.href}
            {...props}
        > 
            {content}  
        </Link>
    );
}

export default RouteLink;