import ProjectedAgeRow from "./projectedAgeRow";
import {useCallback, useEffect, useState} from "react";
import {InputText} from "../common/elements/input";
import Select from "../common/elements/select";
import httpClient from "../../httpClient";
import TopTenRisks from "./topTenRisks";
import _ from "lodash";
import AllBioMarkersChart from "./allBioMarkersChart";

import { stochasticOptions, methodOptions } from "./constants";

const OutputPanel = (props) => {
  const {
    className,
    age,
    gender,
    topTenRisks,
    showPanel,
    correlation,
    setCorrelation,
    setAge,
    dashboardData,
    calculatedRatio,
  } = props;

  const selectedMethod = props.selectedMethod.value;
  const stochasticOption = props.stochasticOption.value;

  const tableCellClassName = `table-cell px-2 py-0.5  border-dotted align-middle`;
  const tableHeadCell = `font-bold`;

  

  const [localAge, setLocalAge] = useState(age);
  const [localGender, setLocalGender] = useState(gender);
  const [localCorrelation, setLocalCorrelation] = useState(correlation);
  const [cError, setCError] = useState("");
  const [basketHazard, setBasketHazard] = useState(0);

  const calculateBasketHazard = useCallback( async () => {
    if(!dashboardData || !Array.isArray(dashboardData) || dashboardData.length < 1 ) {
      return false
    }
    if(dashboardData.filter(risk => risk.hazardRate >0).length < 1) {
      return false;
    }
    let payload = {
      correlation: correlation,
      stochasticOption: stochasticOption,
      selectedMethod: selectedMethod,
      age:age,
      gender:gender,
      hazards:dashboardData,
    };

    let response = await httpClient.post('/risk/v1/getBasketHazard', payload);
    setBasketHazard(_.round(response.basketHazard,2));
    return response.basketHazard;
  },[dashboardData, gender, age, correlation, stochasticOption, selectedMethod]);

  const refreshValues = useCallback(() => {
    setLocalGender(gender);
    setLocalAge(age);
    setLocalCorrelation(correlation);
  },[gender, age, correlation]);

  useEffect(() => {
    refreshValues();
  },[refreshValues]);



  const genderOptions = [
    {value:1, label:'Male'},
    {value:2, label:'Female'},
  ];
  let defaultOption;
  for(let i=0;i<genderOptions.length;i++) {
    if(genderOptions[i].value === localGender) {
      defaultOption = genderOptions[i];
    }
  }



  useEffect(() => {
    refreshValues()
  }, [refreshValues]);


  useEffect(() => {
    calculateBasketHazard()
  }, [calculateBasketHazard]);

  const genderChange = (selectedOption) => {
    setLocalGender(selectedOption.value)
    props.setGender(selectedOption.value)
  }

  const updateCorrelationOnAgeChange = useCallback(() => {
    let floatAge;
    let correlation = 0;
    try {
      floatAge = parseFloat(age);
    } catch (ex) {
      setCorrelation(correlation);
      return ;
    }

    
    if(stochasticOption === 6) {
      if (floatAge <= 40) {
        correlation = 0;
      } else if (floatAge > 80) {
        correlation = 100;
      } else {
        correlation = (floatAge - 40) * 2.5;
      }
    } else if(stochasticOption === 10) {
      if (floatAge <= 35) {
        correlation = 0;
      } else if (floatAge > 90) {
        correlation = 100;
      } else {
        correlation = (floatAge - 35) * (100 / 55);
      }
    }
    correlation = correlation.toFixed(2);
    setCorrelation(correlation);
  },[stochasticOption, age]);

  useEffect(()=>{
    updateCorrelationOnAgeChange()
  },[updateCorrelationOnAgeChange])

  const setCorrelationLocal = async (value) => {


    if(value >100 || value<0) {
      setCError("Enter a number between 0 and 100");
      return;
    } else {
      setCError("");
    }
    setLocalCorrelation(value);
    await debouncedGetAndUpdateBasketHazard(value);

  }

  const getAndUpdateBasketHazard = async value => {
    // await calculateBasketHazard(value)
    setCorrelation(value)
  }


  const ageChange = async (age) => {
    setLocalAge(age);
    await debouncedAgeChange(age);
  }

  const debouncedAgeChange = useCallback(
    _.debounce(setAge, 500)
    , [setAge]);



  const debouncedGetAndUpdateBasketHazard = useCallback(
    _.debounce(getAndUpdateBasketHazard, 1000)
    , [setCorrelation]);

  const localClassNames=` pl-0 md:pl-2 xl:px-2 overflow-x-scroll  border-l-0 md:border-l  \
  border-t-2 md:border-t-0 border-uhblue-light`;

  if(!showPanel) {
    return (
      <div id="outputPanel" className={`${className} ${localClassNames}`}>
        <div className={`align-middle text-center pt-16 text-gray-800`}>
          Please enter at least five biomarker values
        </div>
      </div>
    )
  }


  return (
    <div id="outputPanel" className={`${className} ${localClassNames}`}>
      <div className={`font-bold text-gray-800 mb-2`}>
        Projections
      </div>
      <div className={`flex justify-between items-center border-b-2 border-uhblue-light pb-2`}>
        <div className={`inline-block`}>
          <div>
            Age:
          </div>
          <div className={`w-14 inline-block`}>
            <InputText
              value={localAge}
              onChange={ageChange}
            />
          </div>

        </div>

        <div className={`inline-block`}>
          <div>Gender:</div>
          <div className={`inline-block`}>
            <Select
              value={defaultOption}
              options={genderOptions}
              onChange={genderChange}
              labelKey={'label'}
              valueKey={`value`}
              isClearable={false}
            />
          </div>

        </div>
        
      </div>

      <div className="mt-2  border-b-2 border-uhblue-light pb-2 flex">
        <div className="w-1/3 inline-block pr-2">
          <div>Baseline</div>
          <Select
            value={props.stochasticOption}
            options={stochasticOptions}
            onChange={props.setStochasticOptions}
            labelKey={'label'}
            valueKey={`value`}
            isClearable={false}
          />
        </div>
        <div className="w-1/3 inline-block pr-2">
          <div>Method</div>
          <Select
            value={props.selectedMethod}
            options={methodOptions}
            onChange={props.setSelectedMethod}
            labelKey={'label'}
            valueKey={`value`}
            isClearable={false}
          />
        </div>
        <div className="w-1/3 inline-block">
          <div>Correlation (%)</div>
          <InputText
            value={localCorrelation}
            onChange={setCorrelationLocal}
            error={cError}
          />
        </div>
      </div>

      
      {
        showPanel &&
        <div>
          <div className={`table w-full`}>
            <div className={`table-row`}>
              <div className={`${tableCellClassName} ${tableHeadCell} w-1/2`}>Projected</div>
              <div className={`${tableCellClassName} ${tableHeadCell} text-right`}>Relative Hazard</div>
              <div className={`${tableCellClassName} ${tableHeadCell} text-right`}>Age</div>

            </div>

            <ProjectedAgeRow
              hazardRate={basketHazard}
              title={`UnitHuman Estimate`}
              age={localAge}
              gender={localGender}
              className={tableCellClassName}
              stochasticOption={props.stochasticOption.value}
            />
          </div>


          <div className={`mt-12`}>
            <TopTenRisks
              topTenRisks={topTenRisks}
              correlation={correlation}
              age={age}
              gender={gender}
              calculatedRatio={calculatedRatio}
              selectedMethod={selectedMethod}
            />
          </div>
          <div className={`mt-12`}>
            <AllBioMarkersChart
              dashboardData={dashboardData}
            />
          </div>
        </div>
      }



    </div>
  )
}

export default OutputPanel;