import React, { Fragment } from 'react';
import RouteLink from 'view/common/elements/routeLink';

const Dashboard = (props) => {
return ( 
  <Fragment>
    <div> <RouteLink href='/administration/saveHazards' text='Save Hazards'/></div>
    <div> <RouteLink href='/administration/saveLongitudinalHazards' text='Save Longitudinal Hazards'/></div>
    
  </Fragment>
) 
}

export default Dashboard;