import {useCallback, useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {getChartOptions, chartContainerStyles} from './chartCommon';
import _ from "lodash";

const AllBioMarkersChart = (props) => {
  const {dashboardData} = props;
  const [chartData, setChartData] = useState([]);

  const calculateChart = useCallback(() => {
    const headers = ["Items", "Hazard", {'type': 'string', 'role': 'style'}];
    const sortedRisks = _.sortBy(dashboardData,['hazardRate'] );
    const localChartData = [];
    localChartData.push(headers);
    const dataWithHazards = sortedRisks.filter((data) => {
      return data.hazardRate >= 1
    })
    dataWithHazards.map((data, idx)=>{
      localChartData.push([data.biomarker, data.hazardRate,'point { size: 2; shape-type: circle;  }']);
      return false;
    })
    // console.log(dashboardData)
    // console.log(chartData)
    setChartData(localChartData);
  },[dashboardData]);


  useEffect(() => {
    calculateChart();
  },[calculateChart]);


  const chartOptions = getChartOptions("All Biomarkers","Biomarkers","Hazard");
  chartOptions.hAxis.textStyle.fontSize=7;
  return (
    <div className={chartContainerStyles}>
      <Chart
        chartType="ScatterChart"
        data={chartData}
        options={chartOptions}
        loader={<div>Drawing Chart</div>}
      />
    </div>
  )
}

export default AllBioMarkersChart;