import Unit from "./unit";
import PropTypes from "prop-types";
import _ from 'lodash';
import BiomarkerNameContainer from "./biomarkerNameContainer";
import { UsersIcon } from '@heroicons/react/solid'
  
const RiskOutputPanel = (props) => {
  const {
    biomarker,
    biomarkerId,
    unit,
    value,
    hazardRate,
    longit,
  } = props;
  const tableCellClassName = `table-cell px-2 py-0.5 `;
  const paddedBox = `inline-block width-auto px-3`;
  return (
    <div className={`table-row odd:bg-white even:bg-uhblue-light`} key={`biomarker__${biomarkerId}`}>
      <div className={`${tableCellClassName} w-1/3`}>
        <div className={`mr-10 text-sm`}>
          {
            longit && <UsersIcon className="h-3 w-3 inline-block mr-1"/>
          }
          <BiomarkerNameContainer
            name={biomarker}
            shortName={props.shortName}
          />
        </div>
      </div>
      <div className={`${tableCellClassName} w-1/3 text-right`}>
        <div className={`inline-block align-middle `}>
          <div className={`${paddedBox}`}>
            {_.round(value,2)}
          </div>

          <Unit
            unit={unit}
          />
        </div>
      </div>
      <div className={`${tableCellClassName} w-1/6 text-right`}>

          {hazardRate?hazardRate.toFixed(2):hazardRate}
      </div>

      <div className={`${tableCellClassName} w-1/6`}>

      </div>
    </div>
  )
}

RiskOutputPanel.propTypes = {
  biomarker: PropTypes.string.isRequired,
  biomarkerId: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hazardRate: PropTypes.number.isRequired,
}

export default RiskOutputPanel;