import React from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './notification.css'


const defaultOptions = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Bounce,
};

const Notification = {};

Notification.info = (msg = "Hello") => {
    toast.info(msg,{
      className:'notification-info',
      ...defaultOptions
    })
}

Notification.error = (msg = "Hello") => {
    toast.error(msg,{
      className:'notification-error',
      ...defaultOptions
    })
}

Notification.success = (msg = "Hello") => {
    toast.success(msg,{
      className:'notification-success',
      ...defaultOptions
    })
}

export const NotificationContainer = () => {
    return <ToastContainer />
}

export default Notification;