import React,{useContext, useState} from 'react';
import Item from './item';
import {MenuIcon} from '@heroicons/react/solid'
import PermissionContext from 'contexts/permissionContext';
import { isAdminUser } from 'helpers/permission';

const Menu = ({logout, isLoggedIn}) => {
    const [menuClass, setMenuClass] = useState('hidden');

    const permissions = useContext(PermissionContext);
    const toggleMenu = () => {
        if(menuClass==='hidden') {
            setMenuClass('')
        } else {
            setMenuClass('hidden');
        }
    }
    let visibilityClass = "";
    if(!isLoggedIn) {
        visibilityClass = `invisible`;
        // setMenuClass(`hidden`)
    }
    const logoutHandler  = () => {
        toggleMenu();
        logout();
    }
    

    return (
        <React.Fragment>
            <div className={`pr-6 ${visibilityClass}`}>
                <div className={`p-2 hover:bg-uhblue hover:bg-opacity-40 rounded-full cursor-pointer`}  onClick={toggleMenu}>
                    <MenuIcon className='text-white h-7 w-7 block'/>
                </div>
            </div>
            <div className={`w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6 block flex-grow  ${menuClass} absolute  \
            bg-white top-10 left-10 z-10 shadow-xl \
            border-uhblue-dark border rounded px-6 py-4 `}>
              <div className="text-sm">

                {/*<Item*/}
                {/*  to="/hazards"*/}
                {/*  label="Hazards"*/}
                {/*  onClick={toggleMenu}*/}
                {/*/>*/}

                {/*<Item*/}
                {/*  to="/organs"*/}
                {/*  label="Organs"*/}
                {/*  onClick={toggleMenu}*/}
                {/*/>*/}

                {/*<Item*/}
                {/*  to="/biomarkers"*/}
                {/*  label="Biomarkers"*/}
                {/*  onClick={toggleMenu}*/}
                {/*/>*/}

                {/*<Item*/}
                {/*  to="/molecules"*/}
                {/*  label="Molecules"*/}
                {/*  onClick={toggleMenu}*/}
                {/*/>*/}

                {/*<Item*/}
                {/*  to="/interventions"*/}
                {/*  label="Interventions"*/}
                {/*  onClick={toggleMenu}*/}
                {/*/>*/}

                <Item
                    to="/risk"
                    label="Risk Dashboard"
                    onClick={toggleMenu}
                />
                {isAdminUser(permissions) && <Item
                    to="/administration"
                    label="Administration"
                    onClick={toggleMenu}
                />}
                <Item
                    to="/logout"
                    label="Logout"
                    onClick={logoutHandler}
                />
                

                
              </div>
            </div>
            {/*<OutlineButton*/}
            {/*  text="Logout"*/}
            {/*  className="blue"*/}
            {/*  onClick={logout}*/}
            {/*/>*/}
        </React.Fragment>
    )
}

export default Menu;