
import logo from '../../img/uh_logo_bullet_white.png'

const Footer = () => {
    const y = new Date().getFullYear();
    return(
        <footer className="footer pt-1 text-white bg-gradient-to-br from-uhblue-darkest to-uhgreen font-bold">
            <div className="container mx-auto px-6">

                <div className="sm:flex sm:mt-8">
                    <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
                        <div className="flex flex-col">
                            <a href="/privacy">Privacy</a>
                            <a href="/terms">Terms</a>
                        </div>
                        <div className="flex flex-col">
                            <img className="logo" src={logo} alt="UnitHuman Logo"/>
                        </div>
                        <div className="flex flex-col">
                            <a href="mailto:info@UnitHuman.co">info@UnitHuman.co</a> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-6">
                <div className="mt-5 flex flex-col items-center">
                    <div className="sm:w-2/3 text-center py-1">
                        <p className="text-sm text-700 font-bold mb-1">
                            ©{y} UnitHuman Inc. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;