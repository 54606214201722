import {useCallback, useEffect, useState} from "react";
import httpClient from "../../httpClient";

import InputPanel from "./inputPanel";
import OutputPanel from "./outputPanel";


import Loader from "../common/elements/loader";
import SensitivityPanel from "./sensitivityPanel";
import SavePortfolioPanel from "./savePortfolioPanel";
import constants, { defaultMethodOption, defaultStochasticOption } from "./constants";

const RiskDashboard = () => {
  useEffect(() => {
    document.title = 'Risk Dashboard';
    getData();
  },[]);


  const [firstValueReceived, setFirstValueReceived] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [gender, setGender] = useState(constants.DEFAULT_GENDER);
  const [age, setAge] = useState(constants.DEFAULT_AGE);
  const [correlation, setCorrelation] = useState(constants.DEFAULT_CORRELATION);


  const [topTenRisks, setTopTenRisks] = useState([]);

  const [stochasticOption, setStochasticOptions] = useState(defaultStochasticOption);
  const [selectedMethod, setSelectedMethod] = useState(defaultMethodOption);

  const [calculatedRatio, setCalculatedRatio] = useState('');

  const updateValue = (hazardData) => {
    console.log(hazardData);
    if(hazardData.hazardRate) {
      hazardData.hazardRate = parseFloat(hazardData.hazardRate);
    } else {
      hazardData.hazardRate=0;
    }

    const newDashboardData = [...dashboardData];

    for( let i=0;i<newDashboardData.length;i++) {
      if(newDashboardData[i].biomarkerId === hazardData.biomarkerId && newDashboardData[i].unitId===hazardData.unitId) {
        newDashboardData[i].value = hazardData.value;
        newDashboardData[i].hazardRate = hazardData.hazardRate;
        newDashboardData[i].outOfBounds = hazardData.outOfBounds;
        if(newDashboardData[i].outOfBounds) {
          newDashboardData[i].minValue = hazardData.minValue;
          newDashboardData[i].maxValue = hazardData.maxValue;
        }

      }
    }

    setDashboardData(newDashboardData);
    setOutputData(newDashboardData);

  }

  const setOutputData = async dashboardData => {
    // console.log('setOutputData', dashboardData);
    if(dashboardData && dashboardData.values && Array.isArray(dashboardData.values)) {
      dashboardData = dashboardData.values;
    }
    if(!dashboardData || !Array.isArray(dashboardData)||dashboardData.length<1) {
      return;
    }
    
    
    
    const risksWithHazards = dashboardData.filter( data => data.hazardRate > 0);
    let minBiomarkersRequired=1;
    // console.log('setOutputData1', dashboardData, risksWithHazards);
    if(risksWithHazards.length >= minBiomarkersRequired) {
      setFirstValueReceived(true);
    } else  {
      setFirstValueReceived(false);
    }
  }

  const populatePortfolioData = (newDashboardData) => {
    // console.log('populatePortfolioData', newDashboardData);
    if(newDashboardData) {
      if(newDashboardData.age) {
        setAge(newDashboardData.age);
      }
      
      if(newDashboardData.correlation) {
        setCorrelation(newDashboardData.correlation);
      }
      if(newDashboardData.gender) {
        setGender(newDashboardData.gender);
      }
      if( newDashboardData.values && Array.isArray(newDashboardData.values)) {
        setDashboardData(newDashboardData.values);
        setOutputData(newDashboardData.values);
      } else {
        setDashboardData(newDashboardData);
        setOutputData(newDashboardData);
      }
      
    }
    
    
  }

  const getData = async () => {
    let data =  await httpClient.get('/risk/v1/getDefaultDashboardData');
    // debugger;
    if(!data) {
      data={
        biomarkers:[]
      };
    }
    setDashboardData(data.biomarkers);
    setAge(parseInt(data.age));
    setGender(parseInt(data.gender));
  }

  const panelClassNames = 'w-full md:w-1/2 xl:w-1/3 pt-3 inline-block align-top h-96 md:h-screen';

  

  const correlationUpdated = (newCorrelation) => {
    setCorrelation(newCorrelation)
  }

  const getTopTenRisks = useCallback( async () => {
    setTopTenRisks([]);
    setCalculatedRatio('');
    if(!dashboardData || !Array.isArray(dashboardData) || dashboardData.length < 1 ) {
      return false
    }
    if(dashboardData.filter(risk => risk.hazardRate >0).length < 1) {
      return false;
    }
    const response = await httpClient.post('/risk/v1/getTopTenRisks', {
      hazards: dashboardData,
      method: selectedMethod.label,
      age: age,
      gender: gender,
    });
    setTopTenRisks(response.topTenRisks);
    setCalculatedRatio(response.ratio);
  },[dashboardData, selectedMethod, age, gender]);

  useEffect(()=>{
    getTopTenRisks();
  },[getTopTenRisks])
  
  if(!dashboardData) {
    return (
      <Loader/>
    )
  }
  return (
    <div>
      
      <SavePortfolioPanel
        dashboardData={dashboardData}
        populatePortfolioData={populatePortfolioData}
        age={age}
        gender={gender}
        correlation={correlation}
      />
      <InputPanel
        dashboardData={dashboardData}
        className={`${panelClassNames}`}
        onChange={updateValue}
      />
      <OutputPanel
        age={age}
        gender={gender}
        className={`${panelClassNames}`}
        topTenRisks={topTenRisks}
        showPanel={firstValueReceived}
        correlation={correlation}
        setCorrelation={correlationUpdated}
        setAge={setAge}
        setGender={setGender}
        dashboardData={dashboardData}
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        stochasticOption={stochasticOption}
        setStochasticOptions={setStochasticOptions}
        calculatedRatio={calculatedRatio}
      />
      

      <SensitivityPanel
        age={age}
        gender={gender}
        topTenRisks={topTenRisks}
        showPanel={firstValueReceived}
        correlation={correlation}
        className={`${panelClassNames}`}
      />

    </div>
  );
}

export default RiskDashboard;
