import React from 'react';
import { Popover as LibPopover, ArrowContainer } from 'react-tiny-popover'

const Popover = (props) => {
  return ( 
    <LibPopover
      isOpen={props.isPopOverOpen}
      positions={['right', 'top', 'left', 'bottom']}
      padding={4}
      onClickOutside={() => props.setPopOverOpen(false)}
      
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'var(--uh-blue)'}
          arrowSize={5}
          arrowStyle={{ opacity: 1 }}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <div
            style={{ backgroundColor: 'var(--uh-blue)', opacity: 1, color:'white'}}
            onClick={() => props.setPopOverOpen(!props.popOverOpen)}
            className='px-2 py-1'
          >
            {props.content}
          </div>
        </ArrowContainer>
      )}
    >
      {props.children}
    </LibPopover>
  ) 
}

export default Popover;