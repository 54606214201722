import {InputText} from "view/common/elements/input";
import httpClient from "../../httpClient";
import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import Unit from "./unit";
import Loader from "../common/elements/loader";
import {InformationCircleIcon} from '@heroicons/react/solid'
import Tooltip from 'view/common/elements/tooltip'
import BiomarkerNameContainer from "./biomarkerNameContainer";

const BiomarkerInput = (props) => {
  const { 
    biomarkerId, 
    unit, 
    value, 
    hazardRate, 
    unitId, 
    onChange, 
    outOfBounds, 
    minValue, 
    maxValue,
  } =
    {hazardRate:"-", risk:"-",...props};
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const updateValue = async (value) => {
    setLocalValue(value);
    const data =  {
      'biomarkerId':biomarkerId,
      'unitId':unitId,
      'value':value
    }
    setLoading(true);
    await debouncedDataFetch(data);
    setLoading(false);
  }

  const dataFetch = async data => {
    const serverData = await httpClient.post('/risk/v1/getHazardRate',data);
    onChange(serverData);
  }
  const debouncedDataFetch = useCallback(
    _.debounce(dataFetch, 800)
    , [onChange]);
  // const commonClassNames = `align-middle table-cell border-dashed border-b border-slate-300 px-0.5`;
  const commonClassNames = `align-middle table-cell px-0.5`;
  useEffect(()=>{
    setLocalValue(props.value);
  },[props.value]);

  return (
    <div className='biomarkerInput odd:bg-white even:bg-uhblue-light table-row w-1/4'>

      <div className={`biomarker ${commonClassNames}`}>
        <BiomarkerNameContainer 
          name={props.biomarker}
          shortName={props.shortName}
        />
      </div>

      <div className={`input ${commonClassNames} w-1/6 relative`}>
        <InputText
          id={`biomarker_${biomarkerId}_value`}
          value={localValue}
          onChange={updateValue}
          className={`m-0 my-0.5`}
        />
        {
          outOfBounds &&
          <span>
            <InformationCircleIcon
              className={`text-red-700 h-5 w-5 absolute top-2 right-1 cursor-pointer`}
              id={`biomarker_${biomarkerId}_oob`}
            />
            <Tooltip
              position={'right'}
              content={`Accepted range is from ${_.round(minValue,2)} to ${_.round(maxValue,2)}`}
              anchorId={`biomarker_${biomarkerId}_oob`}
            />
          </span>
        }
      </div>
      <div className={`unit  ${commonClassNames}  w-1/6`}>
        {
          unit !== 'ABS'  &&
          <Unit
            unit={unit}
          />
        }

      </div>
      <div className={`hazardRate w-11 text-center ${commonClassNames} w-1/6`}>
        {
          loading &&
          <Loader
            mainLoader={false}
          />
        }
        {
          !loading && hazardRate>0 && <span>{hazardRate}</span>
        }
        {
          !loading && !hazardRate && <span>-</span>
        }
      </div>
      {/*<div className={`risk ${commonClassNames}`}>*/}
      {/*  {risk}*/}
      {/*</div>*/}
    </div>
  )
}

export default BiomarkerInput;