import React from 'react';
import PropTypes from "prop-types";
import './input.css';

const TextBox = (props) => {
  const {width, height, spellCheck} = {width:80, height:10, spellCheck:false, ...props};

  const onChange = (e) => {
    props.onChange(e.target.value);
  }

  let stateClassName='';
  let msg;

  if(props.info) {
    stateClassName='info';
    msg=props.info;
  }

  if(props.error) {
    stateClassName='error';
    msg=props.error;
  } 

  return ( 
    <div className={`relative ${props.className}`}>
      <textarea 
        rows={height} 
        cols={width} 
        spellCheck={spellCheck} 
        name={props.name} 
        onChange={onChange}
        value={props.content}
        className='border border-1 rounded px-2 py-1 border-uhblue'
        onFocus={props.onFocus}
        placeholder={props.placeholder}
      />
      <div className={`inputMsg ${stateClassName}`}>
        {msg}
      </div>
    </div>
      
      
  ) 
}

TextBox.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextBox;