import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Popover from 'view/common/elements/popover';


const BiomarkerNameContainer = (props) => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const mouseEnter = () => {
    setPopOverOpen(true)
  }
  const mouseLeave = () => {
    setPopOverOpen(false)
  }
  return ( 
    <Fragment>

    
    {
      props.shortName && 
      <Popover
        content={props.name}
        isPopOverOpen={popOverOpen}
      >
        <div 
          onMouseEnter={mouseEnter} 
          onMouseLeave={mouseLeave} 
          className='w-auto inline-block border-b border-uhblue border-dashed pointer' 
        >
          {props.shortName}
        </div>
      </Popover>
    }
    {
      !props.shortName && <Fragment>{props.name}</Fragment>
    }
    
    </Fragment>
  ) 
}

BiomarkerNameContainer.propTypes = {
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string,
}

export default BiomarkerNameContainer;