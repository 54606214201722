const constants = {
  DEFAULT_AGE : 35,
  DEFAULT_GENDER : 1,
  DEFAULT_CORRELATION : 2.5,
}

export const stochasticOptions = [
  {
    label:'10 @ 6x',
    value:6,
  },
  {
    label:'All @ 10x',
    value:10,
  },
  {
    label:'at 4x [OLD]',
    value:4,
  },
];

export const defaultStochasticOption = {
  label:'10 @ 6x',
  value:6,
};

export const methodOptions = [
  {
    label:'GIVEN',
    value:1,
  },
  {
    label:'LONGIT',
    value:2,
  },
  {
    label:'RATIO',
    value:3,
  },
]

export const defaultMethodOption = {
  label:'GIVEN',
  value:1,
};

export default constants;