import {useCallback, useEffect, useState} from "react";
import httpClient from "../../httpClient";
import Loader from "../common/elements/loader";
import {Chart} from "react-google-charts";
import {getChartOptions, chartContainerStyles} from './chartCommon';

const SensitivityChart = (props) => {
  const {biomarker, unit, topTenRisks} = props;
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const getSensitivityData = useCallback( async () => {
    if(!topTenRisks || !Array.isArray(topTenRisks) || topTenRisks.length <1) {
      return false;
    }
    setLoading(true);
    let postURL = '/risk/v1/getSensitivityData';
    if(props.biomarker==='Correlation') {
      postURL = '/risk/v1/getCorrelationSensitivity';
    }
    const ages = await httpClient.post(postURL, {
      hazards: topTenRisks,
      biomarker:props.biomarker,
      age:props.age,
      gender:props.gender,
      correlation:props.correlation,
    });
    const newGraphData = [['Value', 'Years', {'type': 'string', 'role': 'style'}]];
    let maxAge = 0;
    if( ages && Array.isArray(ages)) {
      maxAge  = Math.max(...ages.map(o => o.projectedAge));
    }


    let pointStyle=null;
    for(let i=0;i<ages.length;i++) {
      if( parseFloat(ages[i].value)===parseFloat(props.value) ||
        (i<ages.length-1 &&
          parseFloat(ages[i].value)< parseFloat(props.value) &&
            parseFloat(ages[i+1].value)> parseFloat(props.value)) ) {
        pointStyle = 'point { size: 5; shape-type: circle;  }';
      } else {
        pointStyle = null;
      }
      newGraphData.push([ages[i].value, maxAge-ages[i].projectedAge, pointStyle]);
    }
    setGraphData(newGraphData);
    setLoading(false);
  },[topTenRisks, props.correlation, props.gender, props.age, props.biomarker, props.value])

  useEffect(() => {
    getSensitivityData()
  }, [getSensitivityData]);

  const chartOptions = getChartOptions(`${biomarker} (${unit})`,`${biomarker} (${unit})`, 'Years');

  return (
    <div className={chartContainerStyles}>
      {
        loading &&
        <Loader
          mainLoader={false}
        />
      }
      {
        !loading && graphData &&
        <Chart
          chartType="LineChart"
          data={graphData}
          options={chartOptions}
          loader={<div>Drawing Chart</div>}
        />
      }
    </div>
  )
}

export default SensitivityChart;