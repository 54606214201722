import React, { Fragment } from 'react';

const ExistingLongitudinalHazards = (props) => {
  const tableCellClassnames = 'border px-1 border-uhblue text-center';
  let count=1;
  return ( 
    <div className='mx-2 rounded bg-uhblue-light overflow-y-scroll' style={{maxHeight:'550px'}}>
      {
        props.existingHazards.length <= 0 && 
        <div className='py-3 flex items-center justify-center font-bold'> 
          No existing longitudinal hazard rates were found for {props.biomarker} [{props.unit}]
        </div>
      }
      {
        props.existingHazards.length > 0 && 
        <Fragment>
          <div className='py-3 flex items-center justify-center font-bold'> 
            Existing longitudinal hazard rates for {props.biomarker}
          </div>
          <div className='flex items-center justify-center text-sm pb-5'>
            <table className='border-collapse'>
              <thead>
                <tr>
                    <th className={tableCellClassnames}>#</th>
                    <th className={tableCellClassnames}>Age</th>
                    <th className={tableCellClassnames}>Model Biomarker Value [{props.unit}] </th>
                    <th className={tableCellClassnames}>Hazard Rate</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.existingHazards.map((hazard) => {
                    return(
                      <tr key={hazard.id}>
                        <td className={tableCellClassnames}>
                          {count++}
                        </td>
                        <td className={tableCellClassnames}>
                          {hazard.age}
                        </td>
                        <td className={tableCellClassnames}>
                          {hazard.modelBiomarkerValue}
                        </td>
                        <td className={tableCellClassnames}>
                          {hazard.hazardRate}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </Fragment>
      }
      

    </div>
  ) 
}

export default ExistingLongitudinalHazards;